// extracted by mini-css-extract-plugin
export var bgDark = "bbq-module--bg-dark--3ylGj";
export var container = "bbq-module--container--297ZU";
export var containerCenter = "bbq-module--containerCenter--2iXjx";
export var bgImage = "bbq-module--bg-image--2a2gk";
export var zoomIn = "bbq-module--zoom-in--uWJVD";
export var highlight = "bbq-module--highlight--1ryDV";
export var highlightNormal = "bbq-module--highlight-normal--1rhP7";
export var priceContainer = "bbq-module--price-container--2U0YC";
export var line = "bbq-module--line--1-eqQ";
export var menuTitle = "bbq-module--menu-title--2EDmE";
export var menuTitleEnglish = "bbq-module--menu-title-english--cWKBv";
export var subMenuTitle = "bbq-module--sub-menu-title--2Zt4R";
export var menuPriceOne = "bbq-module--menu-price-one--2io5T";
export var menuContainer = "bbq-module--menu-container--9O56U";
export var menuDescription = "bbq-module--menu-description--1tDlZ";
export var menuList = "bbq-module--menu-list--bYkBk";
export var tax = "bbq-module--tax--2G99n";
export var menuItemDrink = "bbq-module--menu-item-drink--2xZB_";
export var price = "bbq-module--price--XtDbI";
export var lineMenuHeader = "bbq-module--line-menu-header--2pT98";
export var lineMenuHeaderWhite = "bbq-module--line-menu-header-white--2JG4M";
export var flexColumnContainer = "bbq-module--flex-column-container--3e5eK";